import "@fontsource/poppins/300.css";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/700.css";
import "@fontsource/poppins/900.css";
import "@fontsource/space-mono";
import { useStaticQuery, graphql } from "gatsby";
import { SEOContext } from "gatsby-plugin-wpgraphql-seo";
import React from "react";

import { Footer } from "src/components/Footer/Footer";
import { Header } from "src/components/Header/Header";

export const Layout = ({ invertHeader, children, footerFixed }) => {
  const {
    wp: { seo },
  } = useStaticQuery(graphql`
    query SiteInfoQuery {
      wp {
        seo {
          contentTypes {
            post {
              title
              schemaType
              metaRobotsNoindex
              metaDesc
            }
            page {
              metaDesc
              metaRobotsNoindex
              schemaType
              title
            }
          }
          webmaster {
            googleVerify
            yandexVerify
            msVerify
            baiduVerify
          }
          schema {
            companyName
            personName
            companyOrPerson
            wordpressSiteName
            siteUrl
            siteName
            inLanguage
            logo {
              sourceUrl
              mediaItemUrl
              altText
            }
          }
          social {
            facebook {
              url
              defaultImage {
                sourceUrl
                mediaItemUrl
              }
            }
            instagram {
              url
            }
            linkedIn {
              url
            }
            mySpace {
              url
            }
            pinterest {
              url
              metaTag
            }
            twitter {
              username
            }
            wikipedia {
              url
            }
            youTube {
              url
            }
          }
        }
      }
    }
  `);
  return (
    <SEOContext.Provider value={{ global: seo }}>
      <div className="global-wrapper">
        <Header invertHeader={invertHeader} />
        <main>{children}</main>
        <Footer footerFixed={footerFixed} />
      </div>
    </SEOContext.Provider>
  );
};
