import classNames from "classnames";
import { Link, useStaticQuery, graphql } from "gatsby";
import React from "react";

import IconScroll from "src/assets/images/ICON-scroll.inline.svg";
import Logo from "src/assets/images/isovera-logo.inline.svg";
import IconLinkedIn from "src/assets/images/social_LinkedIn.inline.svg";
import IconInstagram from "src/assets/images/social_instagram.inline.svg";
import IconTwitter from "src/assets/images/social_twitter.inline.svg";
import { Button } from "src/components/Button/Button";

import "./Footer.scss";

export const Footer = ({ footerFixed }) => {
  const data = useStaticQuery(
    graphql`
      {
        allWp {
          nodes {
            themeSettings {
              acfThemeSettings {
                mothershipLink
              }
            }
          }
        }
      }
    `
  );
  const scrollToTop = () => window["scrollTo"]({ top: 0, behavior: "smooth" });
  const mothershipLink = data.allWp.nodes[0].themeSettings.acfThemeSettings.mothershipLink;

  return (
    <footer className={classNames("footer", footerFixed ? "footer--fixed" : "")}>
      <div className="footer__connect">
        <div className="footer__logo">
          <Link to={mothershipLink} className="c-white-link">
            <Logo />
          </Link>
        </div>
        <div className="footer__icons">
          <a href="https://twitter.com/isovera" target="_blank" rel="noreferrer" className="c-white-link twitter">
            <IconTwitter />
          </a>
          <a href="https://www.linkedin.com/company/isovera-llc/" target="_blank" rel="noreferrer" className="c-white-link linkedin">
            <IconLinkedIn />
          </a>
          <a href="https://www.instagram.com/isovera_agency/" target="_blank" rel="noreferrer" className="c-white-link instagram">
            <IconInstagram />
          </a>
        </div>
      </div>
      <div className="footer__scroll-icon">
        <Button className="scroll-to-top c-white-link" onClick={scrollToTop}>
          <IconScroll />
        </Button>
      </div>
    </footer>
  );
};
