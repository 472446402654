import classNames from "classnames";
import { Link, useStaticQuery, graphql } from "gatsby";
import React from "react";

import Mothership from "src/assets/images/icon-mothership.inline.svg";
import Logo from "src/assets/images/isovera-logo.inline.svg";

import "./Header.scss";
import "./MothershipAnimation.scss";

export const Header = (props) => {
  const data = useStaticQuery(
    graphql`
      {
        allWp {
          nodes {
            themeSettings {
              acfThemeSettings {
                mothershipLink
              }
            }
          }
        }
      }
    `
  );
  const mothershipLink = data.allWp.nodes[0].themeSettings.acfThemeSettings.mothershipLink;

  return (
    <header className={classNames("header", props.invertHeader ? "header--invert " : "header--primary ")}>
      <Link to="/" className={classNames("header__logo", props.invertHeader ? "c-primary-link" : "c-white-link")}>
        <Logo />
      </Link>
      <Link to={mothershipLink} className={classNames("header__mothership", props.invertHeader ? "c-primary-link" : "c-white-link")}>
        <span className="u-show-tablet-landscape-up font-space-mono">Take me to the Mother Ship </span>
        <Mothership className="header__mothership-icon" />
      </Link>
    </header>
  );
};
