import { Link } from "gatsby";
import React from "react";

import "./Button.scss";

export const Button = ({ className, link, href, type = "button", onClick, children }) => {
  if (href) {
    return (
      <Link to={link} className={className}>
        {children}
      </Link>
    );
  }
  return (
    <button type={type} className={className} onClick={onClick}>
            {children}     
    </button>
  );
};
