import classNames from "classnames";
import React from "react";

import "./SectionHeading.scss";

export const SectionHeading = ({ title, subtitle, className, innerHTML }) => {
  if (innerHTML) {
    if (subtitle) {
      return (
        <div className={classNames("section-heading", className)}>
          <h2 className="section-heading__title" dangerouslySetInnerHTML={{ __html: title }}></h2>
          <h3 className="section-heading__subtitle" dangerouslySetInnerHTML={{ __html: subtitle }}></h3>
        </div>
      );
    }
    return (
      <div className={classNames("section-heading", className)}>
        <h2 className="section-heading__title" dangerouslySetInnerHTML={{ __html: title }}></h2>
      </div>
    );
  }

  if (subtitle) {
    return (
      <div className={classNames("section-heading", className)}>
        <h2 className="section-heading__title">{title}</h2>
        <h3 className="section-heading__subtitle">{subtitle}</h3>
      </div>
    );
  }
  return (
    <div className={classNames("section-heading", className)}>
      <h2 className="section-heading__title">{title}</h2>
    </div>
  );
};
